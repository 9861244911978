const FooterMenu = {
    COMPANY: [
      { title: "About Us" },
      { title: "Social Impact" },
      { title: "Policies" },
      { title: "Contact Us" }
    ],
    SERVICE: [
      { title: "Image Annotation Services" },
      { title: "Video Annotation Services" },
      { title: "Text Annotation Services" },
      { title: "Content Moderation Services" },
      { title: "Medical Data Annotation Services" },
      { title: "Product Categorization Services" },
      { title: "Audio Annotation Services" }
    ],
    "ANNOTATION TOOL": [
      { title: "Bounding Box Annotation" },
      { title: "Semantic Segmentation" },
      { title: "Landmark Annotation" },
      { title: "3D Point Cloud Annotation" },
      { title: "3D Cuboid Annotation" },
      { title: "Polyline Annotation" },
      { title: "Polygon Annotation" }
    ]
  };
  
  export default FooterMenu;
  