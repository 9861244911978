import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Logo from "../../assets/images/WhiteLogo.png";
import Menus from "../../helpers/Constants/Menus";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import RoutesName from "../../helpers/Constants/RoutesName";

const NavBAr = () => {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [selectedMenu, setSelectedMenu] = useState(null);
  const [anchorElSubMenu, setAnchorElSubMenu] = useState(null);
  const [showArrow, setShowArrow] = useState("");
  const navigate = useNavigate();

  const handleOpenNavMenu = (event, menu) => {
    setAnchorElNav(event.currentTarget);
    setSelectedMenu(menu);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
    setSelectedMenu(null);
  };

  const handleCloseSubMenu = () => {
    setAnchorElSubMenu(null);
    setSelectedMenu(null);
  };

  const handleSubMenuClick = (event, menu) => {
    event.stopPropagation();
    setAnchorElSubMenu(event.currentTarget);
    setSelectedMenu(menu);
  };
  return (
    <AppBar
      position="static"
      sx={{
        backgroundImage: "linear-gradient(to right, #2364AA,#CD6413)",
      }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box
            onClick={() => {
              navigate("/");
            }}
            component="img"
            src={Logo}
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "50px",
              cursor: "pointer",
            }}
          />
          <Box
            sx={{
              flexGrow: 1,
              display: {
                xs: "flex",
                justifyContent: "right",
                width: "100%",
                md: "none",
              },
            }}
          >
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={(event) => handleOpenNavMenu(event, null)}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {Menus.map((menu) => (
                <MenuItem
                  key={menu.title}
                  onClick={(event) => handleSubMenuClick(event, menu)}
                >
                  <Typography textAlign="center">{menu.title}</Typography>
                </MenuItem>
              ))}
              <Button
                onClick={() => {
                  navigate("/" + RoutesName.contactus);
                }}
                variant="outlined"
                color="inherit"
              >
                Request A Quote
              </Button>
            </Menu>
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex", justifyContent: "right" },
            }}
          >
            {Menus.map((menu) => (
              <Box component="div" key={menu.title}>
                <Button
                  onClick={(event) => handleSubMenuClick(event, menu)}
                  sx={{ color: "white", display: "block" }}
                >
                  {menu.title}
                </Button>
                <Menu
                  id={`submenu-${menu.title}`}
                  anchorEl={anchorElSubMenu}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  open={Boolean(anchorElSubMenu) && selectedMenu === menu}
                  onClose={handleCloseSubMenu}
                  onClick={(event) => event.stopPropagation()}
                >
                  {menu.submenu.map((subMenuItem) => (
                    <MenuItem
                      key={subMenuItem.title}
                      onMouseEnter={() => setShowArrow(subMenuItem.title)}
                      onMouseLeave={() => setShowArrow("")}
                      onClick={()=>navigate("/" + subMenuItem?.path)}
                    >
                      {subMenuItem.title}
                      <KeyboardDoubleArrowRightIcon
                        sx={{
                          opacity:
                            showArrow == subMenuItem.title ? "100%" : "0%",
                          marginLeft:
                            showArrow == subMenuItem.title ? "10px" : "0px",
                          transition: "margin 0.2s ease-in",
                        }}
                        fontSize="small"
                      />
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            ))}
            <Button
              variant="outlined"
              color="inherit"
              sx={{ marginLeft: "5%" }}
              onClick={() => {
                navigate("/" + RoutesName.contactus);
              }}
            >
              Request A Quote
            </Button>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default NavBAr;
