import React from "react";
import { RouterProvider } from "react-router-dom";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Layout from "./layout/index";
import router from "./router";

// Define custom theme with Raleway font
const theme = createTheme({
  typography: {
    fontFamily: 'Raleway, sans-serif',
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <div className="App" style={{height:"fit-content", overflowY:"scroll"}}>
        <RouterProvider router={router}>
          <Layout />
        </RouterProvider>
      </div>
    </ThemeProvider>
  );
}

export default App;
