import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import Container from "@mui/material/Container";
import ImageAnnotationbg from "../../../assets/images/ImgAnnotation.png";
import { useNavigate } from "react-router-dom";
import RoutesName from "../../../helpers/Constants/RoutesName";
import KeyFeatureOfImageAnnotation from "./KeyFeatureOfImageAnnotation/KeyFeatureOfImageAnnotation";
const ImageAnnotation = () => {
  const navigate = useNavigate();
  return (
    <Container
      sx={{ overflowX: "hidden", paddingBottom: "40px", paddingTop: "40px" }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} spacing={0}>
          <Typography
            variant="h4"
            color="initial"
            sx={{
              fontSize: { xs: "1.5rem", md: "2rem" },
              fontWeight: "800",
            }}
          >
            IMAGE ANNOTATION FOR MACHINE LEARNING
          </Typography>
          <Typography variant="h6" color="initial" sx={{ marginTop: "20px" }}>
            Pixelwise annotation service for all types and formats of images
            making easier for computer vision to detect and recognize the varied
            objects just like humans.
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          spacing={0}
          sx={{
            display: "flex",
            justifyContent: { xs: "center", md: "flex-end" },
          }}
        >
          <Box
            component="img"
            src={ImageAnnotationbg}
            sx={{ width: { xs: "350px", md: "400px" } }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid
          item
          columns={12}
          sx={{
            display: "flex",
            justifyContent: { xs: "center", md: "flex-start" },
            width: "100%",
          }}
        >
          <Button
            variant="contained"
            color="inherit"
            sx={{
              backgroundImage: "linear-gradient(to right, #2364AA,#CD6413)",
              marginTop: "4%",
              color: "white",
            }}
            onClick={() => {
              navigate("/" + RoutesName.contactus);
            }}
          >
            Get a Quote
          </Button>
        </Grid>
      </Grid>
      <Divider sx={{ marginTop: "10px" }} />
      <KeyFeatureOfImageAnnotation/>
    </Container>
  );
};

export default ImageAnnotation;
