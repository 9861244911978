import React, { useEffect } from "react";
import { Box, Backdrop } from "@mui/material";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";

const WARNING = "warning";
const SUCCESS = "success";
const INFO = "info";
const ERROR = "error";

const Notification = (props) => {
  const { message, type, handleCloseAlert, open } = props;

  const handleClose = () => {
    handleCloseAlert();
  };

  return (
    <Backdrop
      open={true}
      style={
        message === "" ? { display: "none" } : { display: "block", zIndex: 50 }
      }
    >
      <Box
        sx={{
          position: "fixed",
          top: "10%",
          left: "50%",
          transform: "translateX(-50%)",
          zIndex: 50,
        }}
      >
        <Stack spacing={2} sx={{ width: "fit-content" }}>
          <Alert severity={type} onClose={handleClose}>
            {message}
          </Alert>
        </Stack>
      </Box>
    </Backdrop>
  );
};

export default Notification;
export { WARNING, SUCCESS, INFO, ERROR };
