import { Box, Button, Divider, Grid, Typography } from "@mui/material";
const KeyFeatureOfImageAnnotation = () => {
  return (
    <Box component="div">
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          paddingTop: "40px",
          paddingBottom: "20px",
        }}
      >
        <Typography
          variant="h4"
          color="initial"
          sx={{
            fontSize: { xs: "1.5rem", md: "2rem" },
            textWrap: { md: "nowrap" },
            fontWeight: "800",
          }}
          className="heading"
        >
          Three Key Features of Our Image Annotation Services
        </Typography>
      </Box>
      <Box component="div" sx={{marginTop:"20px"}}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Typography variant="h6" color="initial" className="heading">
              We Excel in Annotating Images for Machine Learning
            </Typography>
            <Divider sx={{ marginTop: "10px" }} />
            <Typography variant="body1" color="initial">
              We annotate all kinds and sizes of images using our tools to make
              them recognizable to machines. We offer training data solutions
              for machine learning in varied fields.
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="h6" color="initial" className="heading">
              We Offer Accurate Imaging Tagging and Labeling Services
            </Typography>
            <Divider sx={{ marginTop: "10px" }} />
            <Typography variant="body1" color="initial">
              Our annotation services involve the use of deep learning to help
              computers and machines in readily recognizing objects via
              dimension and outlined boxes. In involves supplying it with data
              for future reference whilst recognizing similar objects.
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="h6" color="initial" className="heading">
              We Outsource Annotation Services at the Right Price
            </Typography>
            <Divider sx={{ marginTop: "10px" }} />
            <Typography variant="body1" color="initial">
              Our experts can offer you satisfactory results using methods like
              bounding boxes which depend on object type and usability to offer
              you satisfactory results.
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default KeyFeatureOfImageAnnotation;
