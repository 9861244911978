import {
  Box,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import Container from "@mui/material/Container";
import Logo from "../../assets/images/WhiteLogo.png";
import FooterMenu from "../../helpers/Constants/FooterMenu";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { useState } from "react";
import { Link } from "react-router-dom";
const Footer = () => {
  const [showArrow, setShowArrow] = useState(false);
  const hoverArrow = (title, showArrow) => {
    return (
      <KeyboardDoubleArrowRightIcon
        sx={{
          opacity: title == showArrow ? "100%" : "0%",
          marginLeft: title == showArrow ? "10px" : "0px",
          transition: "margin 0.2s ease-in",
        }}
        fontSize="small"
      />
    );
  };
  return (
    <Box component="div">
      <Grid
        sx={{
          padding: "20px",
          backgroundImage: "linear-gradient(to right, #2364AA, #CD6413)",
          color: "white",
        }}
        container
        spacing={2}
      >
        <Grid item xs={12} md={3}>
          <Box
            component="div"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              component="img"
              src={Logo}
              sx={{ display: "flex", justifyContent: "center", width: "50px" }}
            />
            <Typography variant="h6">TechBind Solution Ltd.</Typography>
          </Box>
          <Box
            ccomponent="p"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "5%",
              marginLeft: "7%",
            }}
          >
            <Typography variant="body">
              An affordable data annotation solution tailored for machine
              learning and artificial intelligence enterprises in need of
              premium training data across various industries.
            </Typography>
          </Box>
        </Grid>
        <Grid
          sx={{ display: "flex", flexDirection: "column" }}
          item
          xs={12}
          md={2}
        >
          <Typography variant="h6">Company</Typography>
          <List>
            {FooterMenu["COMPANY"]?.map((e) => {
              return (
                <ListItem disablePadding>
                  <ListItemText
                    onMouseEnter={() => {
                      setShowArrow(e.title);
                    }}
                    onMouseLeave={() => setShowArrow("")}
                  >
                    {e.title} {hoverArrow(e.title, showArrow)}
                  </ListItemText>
                </ListItem>
              );
            })}
          </List>
        </Grid>
        <Grid
          sx={{ display: "flex", flexDirection: "column" }}
          item
          xs={12}
          md={3}
        >
          <Typography variant="h6">Service</Typography>
          {FooterMenu["SERVICE"]?.map((e) => {
            return (
              <ListItem disablePadding>
                <ListItemText
                  onMouseEnter={() => {
                    setShowArrow(e.title);
                  }}
                  onMouseLeave={() => setShowArrow("")}
                >
                  {e.title} {hoverArrow(e.title, showArrow)}
                </ListItemText>
              </ListItem>
            );
          })}
        </Grid>
        <Grid
          sx={{ display: "flex", flexDirection: "column" }}
          item
          xs={12}
          md={3}
        >
          <Typography variant="h6">Annotation Tool</Typography>
          {FooterMenu["ANNOTATION TOOL"]?.map((e) => {
            return (
              <ListItem disablePadding>
                <ListItemText
                  onMouseEnter={() => {
                    setShowArrow(e.title);
                  }}
                  onMouseLeave={() => setShowArrow("")}
                >
                  {e.title} {hoverArrow(e.title, showArrow)}
                </ListItemText>
              </ListItem>
            );
          })}
        </Grid>
      </Grid>
      <Grid
        container
        sx={{
          backgroundImage: "linear-gradient(to right,#CD6413,#2364AA)",
        }}
      >
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
            width: "100%",
            padding: "10px 0",
            color: "white",
          }}
        >
          <Typography variant="body1">
            Copyright © 2024 TechBindSolution.com. All Rights Reserved.{" "}
            <Link
              style={{
                textDecoration: "none",
                color: "blanchedalmond",
                fontWeight: "700",
              }}
            >
              Privacy Policy
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;
