import { Box, Grid, Typography } from "@mui/material";
const WhyTechBind = () => {
  return (
    <Box component="div">
      <Grid
        container
        spacing={2}
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          paddingTop: "40px",
          paddingBottom:"20px"
        }}
      >
        <Grid item>
          <Typography
            className="heading"
            variant="h4"
            color="initial"
            sx={{
              fontSize: { xs: "1.5rem", md: "2rem" },
              textWrap: { md: "nowrap" },
              fontWeight: "800",
            }}
          >
            Why techBind?​
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <Grid
          item
          xs={12}
          md={6}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <Typography variant="body1" color="initial" sx={{ width: "90%" }}>
            <b style={{ color: "#a35612" }}>Quality Assurance:</b> TechBind
            prioritizes accuracy and consistency in data annotation. Through
            stringent quality control measures and expert annotators, it ensures
            that annotated data meets the highest standards, minimizing errors
            and maximizing the utility of the data for client applications.
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <Typography variant="body1" color="initial" sx={{ width: "90%" }}>
            <b style={{ color: "#a35612" }}>Customization and Flexibility:</b>{" "}
            TechBind understands that different clients have unique needs and
            requirements. It offers customizable annotation solutions tailored
            to specific data types, formats, and industry domains. This
            flexibility enables clients to obtain annotations that precisely
            align with their objectives and use cases.
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <Typography variant="body1" color="initial" sx={{ width: "90%" }}>
            <b style={{ color: "#a35612" }}>
              Data Security and Confidentiality:
            </b>{" "}
            Recognizing the importance of data security and confidentiality,
            TechBind employs robust measures to safeguard client data throughout
            the annotation process. It adheres to strict protocols to ensure
            that sensitive information remains protected and confidential at all
            times.
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <Typography variant="body1" color="initial" sx={{ width: "90%" }}>
            <b style={{ color: "#a35612" }}>Cost-effectiveness:</b> TechBind
            offers competitive pricing structures without compromising on
            quality. By optimizing workflows and leveraging advanced
            technologies, it delivers cost-effective annotation solutions that
            provide excellent value for clients' investment.​ ​
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <Typography variant="body1" color="initial" sx={{ width: "90%" }}>
            <b style={{ color: "#a35612" }}>Domain Expertise:</b> With a team of
            domain experts across various industries, TechBind possesses
            in-depth knowledge and understanding of specific data types and
            applications. This expertise enables it to deliver highly accurate
            annotations that cater to the unique requirements of each industry
            sector.​
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <Typography variant="body1" color="initial" sx={{ width: "90%" }}>
            <b style={{ color: "#a35612" }}>Customer Support:</b> With a team of
            domain experts across various industries, TechBind possesses
            in-depth knowledge and understanding of specific data types and
            applications. This expertise enables it to deliver highly accurate
            annotations that cater to the unique requirements of each industry
            sector.​
          </Typography>
        </Grid>
        <Grid item sx={{ display: "flex", justifyContent: "center" }}>
          <Typography
            variant="body1"
            color="initial"
            sx={{ width: "90%", textAlign:{xs:"left",md:"center"} }}
          >
            In summary, <b style={{ color: "#a35612" }}>TechBind</b>{" "}
            distinguishes itself as a leading choice for data annotation
            services by offering superior quality, customization, scalability,
            security, cost-effectiveness, domain expertise, and excellent
            customer support. Clients can trust TechBind to deliver accurate,
            reliable, and tailored annotation solutions that drive success in
            their data-driven initiatives.
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default WhyTechBind;
