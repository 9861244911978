const Menus = [
    {
        title: "Solution",
        submenu: [
            { title: "Image Annotation",path:"imageannotation" },
            { title: "Video Annotation" },
            { title: "Text Annotation" },
            { title: "Data Annotation" },
            { title: "Lidar Annotation" },
            { title: "Sentiment Annotation" },
            { title: "Product Categorization" },
            { title: "Image Segmentation" }
        ]
    },
    {
        title: "Services",
        submenu: [
            { title: "Data Classification" },
            { title: "Image Annotation Service" },
            { title: "Video Annotation Service" },
            { title: "Text Annotation Service" },
            { title: "Audio Annotation Service" },
            { title: "Medical Data Annotation" },
            { title: "Product Categorization Service" }
        ]
    },
    {
        title: "Industries",
        submenu: [
            { title: "Self Driving Car" },
            { title: "Retails" },
            { title: "Robotics" },
            { title: "Agriculture" },
            { title: "Helthcare" },
            { title: "Logistics" },
            { title: "Autonomus Flying" },
            { title: "AI Security Camera" }
        ]
    },
    {
        title: "Resource",
        submenu: [
            { title: "Blogs" }
        ]
    },
    {
        title: "Company",
        submenu: [
            { title: "About Us" },
            { title: "Social Impact" },
            { title: "Policies" },
            { title: "Contact Us" }
        ]
    }
];

export default Menus;
