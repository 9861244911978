import { Box, Grid, Typography } from "@mui/material";
import GenerativeAi from "../../../assets/images/GenerativeAi.png";
import ComputerVision from "../../../assets/images/ComputerVision.png";
import DataProcessing from "../../../assets/images/DataProcessing.png";
import DocumentProcessing from "../../../assets/images/DocumentProcessing.png"; // Using the same image for both "Data Processing" and "Document Processing" for the example
import NaturalLanguageProcessing from "../../../assets/images/NaturalLanguageProcessing.png";
import ComputerModeration from "../../../assets/images/ComputerModeration.png";

const ServiceBeOffer = () => {
  const image_array = [
    { name: "Generative AI", image: GenerativeAi },
    { name: "Computer Vision", image: ComputerVision },
    { name: "Data Processing", image: DataProcessing },
    { name: "Computer Moderation", image: ComputerModeration },
    { name: "Natural Language Processing", image: NaturalLanguageProcessing },
    { name: "Document Processing", image: DocumentProcessing },
  ];

  return (
    <Box component="div">
      <Grid
        container
        spacing={2}
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          paddingTop: "40px",
          paddingBottom: "20px",
        }}
      >
        <Grid item>
          <Typography
            className="heading"
            variant="h4"
            color="initial"
            sx={{
              fontSize: { xs: "1.5rem", md: "2rem" },
              textWrap: { md: "nowrap" },
              fontWeight: "800",
            }}
          >
            Services We Offer?
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <Grid
          item
          sx={{
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <Typography variant="body1" color="initial" sx={{ width: "90%" }}>
            We offer data annotation services for machine learning as per
            client's requirements. Our key service offerings include computer
            vision, natural language processing (NLP), content moderation, data
            processing, document processing and generative AI.
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        sx={{marginTop:"20px"}}
      >
        {image_array.map((e, index) => (
          <Grid
            item
            key={index}
            xs={12}
            md={4}
            sx={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            <Box
              sx={{
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                component="img"
                src={e.image}
                sx={{
                  minWidth: "320px",
                  maxWidth: "320px",
                  minHeight: "160px",
                  maxHeight: "160px",
                  position: "relative",
                  borderRadius: "5px",
                  transition: "transform 0.2s ease-in-out",
                  "&:hover": {
                    transform: "scale(1.05)",
                  },
                }}
                alt={e.name}
              />
              <Typography
                variant="h6"
                color="initial"
                sx={{
                  position: "absolute",
                  bottom: "50%",
                  left: "50%",
                  top:"75%",
                  transform: "translate(-50%, 50%)",
                  padding: "5px 0px",
                  color: "white",
                  fontWeight: "700",
                  textWrap: "nowrap",
                }}
              >
                {e.name}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ServiceBeOffer;
