import { Outlet } from "react-router";
import NavBar from "./NavBar";
import Footer from "./Footer";
import { Box } from "@mui/material";
const Layout = () => {
  return (
    <Box component="div">
      <NavBar />
      <Box sx={{ minHeight: "100vh",display:"flex",justifyContent:"center",alignContent:"center" }}>
        <Outlet />
      </Box>
      <Footer />
    </Box>
  );
};

export default Layout;
