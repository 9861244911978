import { useState, useEffect } from "react";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { ERROR, SUCCESS } from "../../components/Notification";
import Notification from "../../components/Notification";
import Menus from "../../helpers/Constants/Menus"
import emailjs from '@emailjs/browser';
const ContactUS = () => {
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    industry: "",
    message: "",
    phone: "",
  });
  const [message, setMessage] = useState({ text: "", type: "" });
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleCloseAlert = () => {
    setMessage({ text: "", type: "" });
  };

  useEffect(() => {
    handleCloseAlert();
  }, [formData]);

  const validateForm = (e) => {
    e.preventDefault();
    const { first_name, last_name, email, industry, message, phone } = formData;

    if (
      !first_name ||
      !last_name ||
      !email ||
      !industry ||
      !message ||
      !phone
    ) {
      setMessage({ text: "All fields are required", type: ERROR });
      return;
    }
    if (!validateEmail(email)) {
      setMessage({
        text: "Please enter a valid email address",
        type: ERROR,
      });
      return;
    }
    sendEmail()
  };
  const sendEmail = () => {
    setIsLoading(true);
    const serviceId = process.env.REACT_APP_MAILJS_SERVICE_ID;
    const templateId = process.env.REACT_APP_MAILJS_TEMPLET_ID;
    const publicKey = process.env.REACT_APP_MAILJS_PUBLIC_KEY;
    const companyName = process.env.REACT_APP_COMPANY_NAME;

    const templateParams = {
      from_name: formData.first_name + " " + formData.last_name,
      from_email: formData.email,
      to_name: companyName,
      message: formData.message,
      industry: formData.industry,
      contact_number:formData.phone
    };
    console.log(templateId,serviceId,publicKey,templateParams)
    emailjs
      .send(serviceId, templateId, templateParams, publicKey)
      .then((response) => {
        if (response.text === "OK") {
          setFormData({
            first_name: "",
            last_name: "",
            email: "",
            industry: "",
            message: "",
            phone:""
          });
          setTimeout(() => {
            setIsLoading(false);
            setMessage({ text: "Message sent successfully!", type: SUCCESS });
          }, 500);
        } else {
          setMessage({ text: response.text, type: SUCCESS });
        }
      })
      .catch((error) => {
        console.error("Error sending email:", error.message);
      });
  };
  return (
    <Box component="div">
      <Notification
        message={message.text}
        type={message.type}
        handleCloseAlert={handleCloseAlert}
      />
      <Grid
        container
        spacing={2}
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          paddingTop: "40px",
          paddingBottom: "20px",
        }}
      >
        <Grid item>
          <Typography
            className="heading"
            variant="h4"
            color="initial"
            sx={{
              fontSize: { xs: "1.5rem", md: "2rem" },
              textWrap: { md: "nowrap" },
              fontWeight: "800",
            }}
          >
            Get In Touch!
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        sx={{
          display: "flex",
          justifyContent: "center",
          paddingBottom: "20px",
        }}
      >
        <Grid item sx={{ width: { xs: "90%", md: "60%" } }}>
          <form onSubmit={validateForm}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  required
                  id="first_name"
                  name="first_name"
                  label="First Name"
                  value={formData.first_name}
                  onChange={handleChange}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  required
                  id="last_name"
                  name="last_name"
                  label="Last Name"
                  value={formData.last_name}
                  onChange={handleChange}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  required
                  id="email"
                  name="email"
                  type="email"
                  label="Email"
                  value={formData.email}
                  onChange={handleChange}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  required
                  id="phone"
                  name="phone"
                  label="Phone"
                  value={formData.phone}
                  onChange={handleChange}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Industry
                  </InputLabel>
                  <Select
                    fullWidth
                    required
                    labelId="demo-simple-select-label"
                    id="industry"
                    name="industry"
                    label="Industry"
                    value={formData.industry}
                    onChange={handleChange}
                    variant="outlined"
                  >
                    <MenuItem selected>Please Choose an option *</MenuItem>
                    {Menus.filter(
                      (e) => e.title === "Industries"
                    )[0]?.submenu.map((e) => (
                      <MenuItem key={e.id} value={e.title}>
                        {e.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  required
                  id="message"
                  name="message"
                  label="Type message here"
                  value={formData.message}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <Box
              component="div"
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: "10px",
              }}
            >
              <Button
                disabled={isLoading}
                type="submit"
                variant="contained"
                color="inherit"
                sx={{
                  backgroundImage: "linear-gradient(to right, #2364AA,#CD6413)",
                  color: "white",
                }}
              >
                Send Message
              </Button>
            </Box>
          </form>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ContactUS;
