import {
  Box,
  Container,
  Grid,
  Typography,
  Button,
  Divider,
} from "@mui/material";
import HomeImage from "../../assets/images/Home.png";
import { useNavigate } from "react-router-dom";
import RoutesName from "../../helpers/Constants/RoutesName";
import WhyTechBind from "./WhyTechBind";
import ServiceBeOffer from "./ServiceBeOffer";
import ContactUS from "../ContactUs";
const Home = () => {
  const navigate = useNavigate();
  return (
    <Container sx={{ overflowX: "hidden", paddingBottom: "10px",paddingTop:"40px" }}>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography
            variant="body1"
            color="initial"
            sx={{
              fontSize: { xs: "1.5rem", md: "2rem" },
              textWrap: { md: "nowrap" },
              fontWeight: "800",
            }}
          >
            WELCOME
          </Typography>
          <Typography
            variant="body1"
            color="initial"
            sx={{
              fontSize: { xs: "1.5rem", md: "2rem" },
              textWrap: { md: "nowrap" },
              fontWeight: "800",
            }}
          >
            TO PRECISION DATA ANNOTATION
          </Typography>
          <Typography
            variant="body1"
            color="initial"
            sx={{
              fontSize: { xs: "1.5rem", md: "2rem" },
              textWrap: { md: "nowrap" },
              fontWeight: "800",
              marginBottom: "5%",
            }}
          >
            EMPOWERING YOUR AI JOURNEY!
          </Typography>
          <Typography variant="h6" color="initial">
            An affordable data annotation solution tailored for machine learning
            and artificial intelligence enterprises in need of premium training
            data across various industries.
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            justifyContent: { xs: "center", md: "end" },
            alignItems: "center",
            marginTop:"-50px"
          }}
        >
          <Box
            component="img"
            src={HomeImage}
            sx={{ width: { xs: "350px", md: "400px" } }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid
          item
          columns={12}
          sx={{
            display: "flex",
            justifyContent: { xs: "center", md: "flex-start" },
            width: "100%",
          }}
        >
          <Button
            variant="contained"
            color="inherit"
            sx={{
              backgroundImage: "linear-gradient(to right, #2364AA,#CD6413)",
              color: "white",
            }}
            onClick={() => {
              navigate("/" + RoutesName.contactus);
            }}
          >
            Get a Quote
          </Button>
        </Grid>
      </Grid>
      <Divider sx={{ marginTop: "10px" }} />
      <ServiceBeOffer />
      <Divider sx={{ marginTop: "10px" }} />
      <WhyTechBind />
      <Divider sx={{ marginTop: "10px" }} />
      <ContactUS />
    </Container>
  );
};

export default Home;
