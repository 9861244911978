import { createBrowserRouter } from "react-router-dom";
import Layout from "../layout";
import Home from "../pages/Home";
import RoutesName from "../helpers/Constants/RoutesName";
import ContactUS from "../pages/ContactUs";
import ImageAnnotation from "../pages/Solutions/ImageAnnotation";
const router = createBrowserRouter(
    [
        {
            path: "/",
            element: <Layout />,
            children: [
                {
                    path: "/",
                    element: <Home />,
                },
                {
                    path: "/"+RoutesName.contactus,
                    element: <ContactUS />,
                },
                {
                    path: "/"+RoutesName.imageannotation,
                    element: <ImageAnnotation />,
                }
            ],
        },
    ]
);

export default router;